<template>
  <th-wrapper :title="$t('pages.settings.pos.security.cash_drawer.title')">
    <div class="mb-6">
      <th-input-title
        class="mb-2"
        :title="$t('pages.settings.pos.security.cash_drawer.drawer_open.title')"
      />

      <el-radio-group v-model="drawerOpen" size="small">
        <div class="flex flex-col">
          <el-radio label="cash">
            {{ $t('pages.settings.pos.security.cash_drawer.drawer_open.cash') }}
          </el-radio>
          <el-radio label="always">
            {{
              $t('pages.settings.pos.security.cash_drawer.drawer_open.always')
            }}
          </el-radio>
        </div>
      </el-radio-group>
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    drawerOpen: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.drawer_open.finish_payment',
          'always'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.drawer_open.finish_payment',
          value: newValue
        })
      }
    }
  }
}
</script>

<template>
  <th-wrapper :title="$t('pages.settings.pos.security.hide_sum_of_cart.title')">
    <div class="mb-4">
      <el-switch
        v-model="hideSumCart"
        :active-text="$t('pages.settings.pos.security.hide_sum_cart.text')"
      />
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    hideSumCart: {
      get() {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.revision_safety.hide_sum_cart'
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.revision_safety.hide_sum_cart',
          value: newValue || false
        })
      }
    }
  }
}
</script>

<template>
  <section>
    <auto-lock />
    <counting-protocols />
    <hide-sum-of-cart />
    <cash-drawer />
    <counting-out />
  </section>
</template>

<script>
import AutoLock from './components/auto-lock'
import CountingProtocols from './components/counting-protocols'
import HideSumOfCart from './components/hide-sum-of-cart'
import CashDrawer from './components/cash-drawer'
import CountingOut from './components/counting-out'

export default {
  components: {
    AutoLock,
    CountingProtocols,
    HideSumOfCart,
    CashDrawer,
    CountingOut
  }
}
</script>

<template>
  <th-wrapper :title="$t('pages.settings.pos.security.auto_lock.title')">
    <!-- Enable -->
    <el-switch
      v-model="autoLockEnabled"
      class="mb-4"
      :active-text="$t('pages.settings.pos.security.auto_lock.enabled.text')"
    />

    <!-- Idle time -->
    <label class="el-form-item__label">
      {{ $t('pages.settings.pos.security.auto_lock.idle_time.text') }}
    </label>
    <th-number-input
      v-model="autoLockIdleTime"
      class="w-40"
      clearable-is-left
      :label="$t('pages.settings.pos.security.auto_lock.idle_time.text')"
      :precision="0"
      :locale="$i18n.locale"
    />
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    autoLockEnabled: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.auto_lock.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.auto_lock.enabled',
          value: newValue
        })
      }
    },
    autoLockIdleTime: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.auto_lock.idle_time'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.auto_lock.idle_time',
          value: newValue || null
        })
      }
    }
  }
}
</script>

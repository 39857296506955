<template>
  <th-wrapper
    :title="$t('pages.settings.pos.security.counting_protocol.title')"
    :info="$t('pages.settings.pos.security.counting_protocol.popover')"
  >
    <!-- Opening register -->
    <div class="mb-6">
      <label class="el-form-item__label mb-2">
        {{ $t('pages.settings.pos.security.counting_protocol.opening.title') }}
      </label>

      <el-radio-group v-model="opening">
        <div class="flex flex-col">
          <el-radio label="required">
            {{
              $t(
                'pages.settings.pos.security.counting_protocol.options.required'
              )
            }}
          </el-radio>
          <el-radio label="none">
            {{
              $t('pages.settings.pos.security.counting_protocol.options.none')
            }}
          </el-radio>
        </div>
      </el-radio-group>
    </div>

    <!-- Closing regiter -->
    <div>
      <label class="el-form-item__label mb-2">
        {{ $t('pages.settings.pos.security.counting_protocol.closing.title') }}
      </label>

      <el-radio-group v-model="closing">
        <div class="flex flex-col">
          <el-radio label="required">
            {{
              $t(
                'pages.settings.pos.security.counting_protocol.options.required'
              )
            }}
          </el-radio>
          <el-radio label="none">
            {{
              $t('pages.settings.pos.security.counting_protocol.options.none')
            }}
          </el-radio>
        </div>
      </el-radio-group>
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    opening: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.register.open.counting_policy'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.register.open.counting_policy',
          value: newValue || 'none'
        })
      }
    },
    closing: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'settings.register.close.counting_policy'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'settings.register.close.counting_policy',
          value: newValue || 'none'
        })
      }
    }
  }
}
</script>

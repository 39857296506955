<template>
  <th-wrapper :title="$t('pages.settings.counting_out.manual_trigger.title')">
    <!-- Manual trigger -->
    <div class="mb-4">
      <el-switch
        v-model="manualTriggerEnabled"
        data-testid="manual-trigger"
        :active-text="$t('common.interactions.switches.enabled_capital')"
      />
    </div>

    <!-- Trigger on Cashier Change -->
    <div class="mb-4">
      <el-switch
        v-model="onCashierChange"
        :active-text="$t('pages.settings.counting_out.on_cashier_change.title')"
      />
    </div>

    <!-- Print Receipt -->
    <div class="mb-4">
      <el-switch
        v-model="printReceipt"
        :active-text="$t('pages.settings.counting_out.receipt_counting.title')"
      />
    </div>

    <!-- Show Discrepancy Warning -->
    <div class="mb-4">
      <el-switch
        v-model="discrepancyWarning"
        :active-text="
          $t('pages.settings.counting_out.discrepancy.warning.title')
        "
      />
    </div>

    <!-- Allow cashier to recount -->
    <div>
      <el-switch
        v-model="discrepancyRecountPermission"
        :active-text="
          $t('pages.settings.counting_out.recount_permission.title')
        "
      />
    </div>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    manualTriggerEnabled: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'countings.manual_trigger'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'countings.manual_trigger',
          value: newValue || false
        })
      }
    },

    onCashierChange: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'countings.on_cashier_change'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'countings.on_cashier_change',
          value: newValue || false
        })
      }
    },

    printReceipt: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'countings.receipt_counting'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'countings.receipt_counting',
          value: newValue || false
        })
      }
    },

    discrepancyWarning: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'countings.discrepancy.warning'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'countings.discrepancy.warning',
          value: newValue || false
        })
      }
    },

    discrepancyRecountPermission: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'countings.discrepancy.recount_permission'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'countings.discrepancy.recount_permission',
          value: newValue || false
        })
      }
    }
  }
}
</script>
